var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.funcList.length > 0
    ? _c("div", { staticClass: "func-box" }, [
        _c("div", { staticClass: "title-box" }, [_vm._v("会议功能")]),
        _c(
          "div",
          { staticClass: "cnt-box" },
          _vm._l(_vm.funcList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                on: {
                  click: function ($event) {
                    return _vm.toFuncModule(item)
                  },
                },
              },
              [
                _c("i-icon", {
                  staticClass: "item-icon",
                  attrs: {
                    name: item.Property.Icon,
                    color: "var(--themeColor)",
                    size: "30",
                  },
                }),
                _c("div", { staticClass: "item-name" }, [
                  _vm._v(_vm._s(item.Property.Txt)),
                ]),
              ],
              1
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }