<template>
    <div id="todoBox">
        <div class="todoBox" @click="show = true">
            <div class="left">
                <i-icon name="icon-jinggao2" color="#f87700"></i-icon>
                <div class="title">{{ config['title'] ? config['title'].template : '' }}</div>
            </div>
            <div class="right">
                <i-icon name="icon-jiantouyou"></i-icon>
            </div>
        </div>
        <i-popup v-model="show" position="bottom" get-container="#todoBox" class="todoListBox">
            <div class="title">{{ config['title'] ? config['title'].template : '' }}</div>
            <template v-if="list.length">
                <div class="listBox" v-if="config['listCard'] && config['listCard'].template">
                    <div class="todoItem" v-for="(todo,todoIndex) in list" :key="todoIndex">
                        <div class="left">
                            <div class="nameBox" v-if="listCardTemplate.title">
                                <div class="dot"></div>
                                <template v-for="(title,titleIndex) in listCardTemplate.title">
                                    <div :class="title['class'] || 'name'" :key="titleIndex">{{ todo[title['key']] }}</div>
                                </template>
                            </div>
                            <template v-if="listCardTemplate.desc">
                                <div :class="desc['class'] || 'desc'" v-for="(desc,descIndex) in listCardTemplate.desc"
                                     :key="descIndex">{{ todo[desc['key']] }}</div>
                            </template>
                            <template v-if="listCardTemplate.tags">
                                <div :class="tag['class'] || 'tag'" v-for="(tag,tagIndex) in listCardTemplate.tags"
                                     :key="tagIndex">{{ todo[tag['key']] }}</div>
                            </template>
                        </div>
                        <div class="right" v-if="listCardTemplate.statusColor">
                            <div :style="{ color: listCardTemplate.statusColor[todo['taskStatus']] }">
                                {{ todo['taskStatusTxt'] }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <empty-box class="listBox" v-if="!list.length" />
            <div class="btnBox" @click="show = false">我知道了</div>
        </i-popup>
    </div>
</template>

<script>
import generic from "../../utils/generic";
import emptyBox from "@/components/iEmpty"

export default {
    name: "todo",
    components: {
        emptyBox
    },
    data() {
        return {
            config: {},
            show: false,
            list: [],
            titleList: [],
            descList: [],
            tagList: [],
            listCardTemplate: {}
        }
    },
    created() {
        this.getConfig()
    },
    methods: {
        getConfig() {
            generic.getConfigs({
                        group: "TodoList",
                        router: "/todoList" })
                    .then((config) => {
                        this.config = config
                        this.listCardTemplate = config['listCard']?.template || {}
                        for (const configKey in config) {
                            if(config[configKey].callback) this[config[configKey].callback]()
                        }
                    });
        },
        async getList() {
            let _config = generic.replaceEvalObject(this.config["list"], this.$smartStorage.get('miceInfo'));
            let res = await this.$service.GenericPost(_config)
            if(res && res.success && res.content) {
                this.list = res.content
            }
        },
    }
}
</script>

<style lang="less" scoped>
.todoBox {
    margin: 0.25rem 0.1rem 0 0.1rem;
    padding: 0.15rem;
    box-sizing: border-box;
    background: #fef1e5;
    border-radius: 0.04rem;
    display: flex;
    .left {
        width: 80%;
        display: flex;
        align-items: center;
        .title {
            color: #f87700;
            margin-left: 0.05rem;
        }
    }
    .right {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
.todoListBox {
    width: 100%;
    height: 80vh;
    padding: 0 0.1rem 0.15rem 0.1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title {
        height: 0.44rem;
        font-size: 0.16rem;
        color: #f87700;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .listBox {
        flex: 1;
        overflow-y: auto;
        .todoItem {
            display: flex;
            margin-bottom: 0.15rem;
            .left {
                width: 85%;
                .nameBox {
                    display: flex;
                    align-items: center;
                    .dot {
                        width: 0.05rem;
                        height: 0.05rem;
                        background: black;
                        border-radius: 50%;
                    }
                    .name {
                        font-size: 0.16rem;
                        margin-left: 0.05rem;
                    }
                }
                .desc {
                    margin-left: 0.1rem;
                }
                .tag {
                    margin: 0.05rem 0 0 0.1rem;
                    width: fit-content;
                    height: 0.2rem;
                    line-height: 0.2rem;
                    text-align: center;
                    vertical-align: middle;
                    background: #fef1e5;
                    border-radius: 0.03rem;
                    font-size: 0.13rem;
                    color: #f87700;
                    margin-right: 0.03rem;
                    padding: 0 0.03rem;
                    box-sizing: border-box;
                }
            }
            .right {
                width: 15%;
                display: flex;
                align-items: center;
            }
        }
    }
    .btnBox {
        height: 0.44rem;
        background: var(--themeColor);
        border-radius: 0.04rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
    }
}
</style>
