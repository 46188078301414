var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.miceInfo
    ? _c("div", { staticClass: "eventProgress" }, [
        _c("div", { staticClass: "titleBox" }, [
          _c("div", { staticClass: "nameBox" }, [
            _c("div", { staticClass: "name" }, [_vm._v("会议进展")]),
            !_vm.info.isMiceStart
              ? _c("div", { staticClass: "tag" }, [_vm._v("未开启")])
              : _vm._e(),
          ]),
          _vm.canStart
            ? _c(
                "button",
                {
                  staticClass: "btn btnBase",
                  on: {
                    click: function ($event) {
                      return _vm.changeStatus("start")
                    },
                  },
                },
                [_vm._v(" 开启会议 ")]
              )
            : _vm._e(),
          _vm.info.isMiceStart || _vm.abnormalMice
            ? _c(
                "button",
                {
                  staticClass: "btnBase",
                  class: _vm.canEnd == "结束会议" ? "btn" : " isDisabled",
                  attrs: { disabled: _vm.canEnd == "会议已结束" },
                  on: {
                    click: function ($event) {
                      return _vm.changeStatus("end")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.canEnd) + " ")]
              )
            : _vm._e(),
        ]),
        _vm.info.isMiceStart
          ? _c("div", { staticClass: "cntBox" }, [
              _c("div", { staticClass: "dateBox" }, [
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.miceOpenTime
                          ? _vm.info.miceOpenTime.split(" ")[1]
                          : "--:--"
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.miceOpenTime
                          ? _vm.formatMoment(_vm.info.miceOpenTime, 1)
                          : "--月--日"
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "center" }, [
                _c("div", { staticClass: "line" }),
                _c("div", { staticClass: "duration" }, [
                  _vm._v(_vm._s(_vm.info.duration) + " 分钟"),
                ]),
                _c("div", { staticClass: "line" }),
              ]),
              _c("div", { staticClass: "dateBox" }, [
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.isMiceEnd && _vm.info.miceEndTime
                          ? _vm.info.miceEndTime.split(" ")[1]
                          : "--:--"
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "date tr" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.info.isMiceEnd && _vm.info.miceEndTime
                          ? _vm.formatMoment(_vm.info.miceEndTime, 1)
                          : "--月--日"
                      ) +
                      " "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }