<template>
    <div class="func-box" v-if="funcList.length > 0">
        <div class="title-box">会议功能</div>
        <div class="cnt-box">
            <div class="item" v-for="(item, index) in funcList" @click="toFuncModule(item)" :key="index">
                <i-icon class="item-icon" :name="item.Property.Icon" color="var(--themeColor)" size="30"></i-icon>
                <div class="item-name">{{ item.Property.Txt }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import generic from "../../utils/generic";
import * as tinyCookie from "tiny-cookie";
import {SmartStorage} from "smart-core-util";
import {getCookie} from 'tiny-cookie';

export default {
    name: "FunctionalModule",
    data() {
        return {
            funcList: [],
            config: {},
            fromPath: '',
            getCookie, // eval无法直接执行，会报错，在此重新定义一次
        };
    },
    props: {
        miceInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    created() {
        this.getConfig();
    },
    methods: {
        getConfig() {
            generic
                .getConfigs({
                    miceId: this.$route.query.miceId,
                    group: "FunctionalModule",
                    router: this.$route.path,
                })
                .then((config) => {
                    console.log('config', config);
                    this.config = config;
                    for (const configKey in config) {
                        if (config[configKey].callback) this[config[configKey].callback]();
                    }
                });
        },
        async init() {
            let extData = this.miceInfo?.ExtData || {};
            let _config = generic.replaceEvalObject(this.config["init"], Object.assign(extData, {
                miceId: this.$route.query.miceId,
                selectedType: this.$smartStorage.get('selectedEventType'),
                status: this.miceInfo.Status, // 用miceinfo外层的status替换到extData里面的status，毕：辉瑞中extData中的不准确
            }));
            const res = await this.$service.GenericPost(_config);
            if (res && res.success && res.content) {
                if (getCookie('tenant') === 'dsm' && this.$smartStorage.get('fullPath') && this.$smartStorage.get('fullPath').indexOf('/EventsComponent') > -1) { // 如果来自住友的会务支持，要隐藏用车授权，来自以前的逻辑
                    this.funcList = res.content.filter(i => i.Property.Txt !== '用车授权');
                } else {
                    this.funcList = res.content;
                }
            }
        },
        async toFuncModule(item) {
            if (item.Property?.type === 'openWebApp') {
                this.openWebApp(item.Property);
            } else {
                console.log(item);
                let type = item.Value;
                //通过store传递一些参数
                // this.$store.commit("setValue", {
                //     key: "summaryConfig",
                //     value: item.clientConfig,
                // });

                const extParams = { // 参数自定义，自取用
                    type: type,
                    readOnly: item.readOnly,
                    OldData: this.miceInfo.ExtData[item.controlName] || "",
                    label: item.Property.Txt,
                    controlName: item.controlName
                }
                if (type === 'MiceInfo') { // 为辉瑞编辑参会信息funcAdd页面添加的参数
                    extParams.isEdit = 1;
                    await this.pfizerBeforeJump(); // 向vuex注入需要编辑的数据
                }
                this.$smartStorage.set('summaryConfig', item.clientConfig);
                this.$router.push({
                    name: item.route ? item.route : "funcList",
                    query: Object.assign(this.$route.query, {...extParams})
                    // query: Object.assign(this.$route.query, {type: type}, {readOnly: item.readOnly}, {OldData: this.miceInfo.ExtData[item.controlName] || ""}, {label: item.Property.Txt}, {controlName: item.controlName}),
                });
            }
        },
        pfizerBeforeJump() {
            return new Promise(resolve => {
                this.$service.QueryConferenceInformations({miceId: this.miceInfo.MiceId}).then(res => {
                    if (res.success) {
                        this.$store.commit("setValue", {
                            key: "funcItemInfo",
                            value: res.content,
                        });
                        resolve();
                    }
                })
            })
        },
        openWebApp(Property) {
            const context = this;
            const appConf = {
                ...Property
            };
            if (Property.paramsExpr) {
                try {
                    console.log(eval(Property.paramsExpr));
                    appConf.params = eval(Property.paramsExpr);
                } catch (e) {
                    console.log(e);
                }
            }
            console.log(appConf);
            this.$publicFun.openWebAppCate({}, appConf);
        },
        // openWebApp(item) {
        //   console.log(item);
        //   let appConf = {
        //     webAppAlias: item.urlKey,
        //     path: item.path,
        //     params: `${item.params}&userId=${tinyCookie.get('userId')}&rnd=${new Date().getTime()}`,
        //   };
        //   this.$publicFun.openWebAppCate({}, appConf, item.noSaveCacheData);
        // },
    }
};
</script>

<style lang="less" scoped>
.func-box {
    padding: 0.15rem 0.18rem 0 0.18rem;
    width: 100%;
    box-sizing: border-box;

    .title-box {
        font-size: 0.16rem;
        font-weight: bold;
        color: #13161A;
    }

    .cnt-box {
        display: flex;
        flex-wrap: wrap;

        .item {
            margin-top: 0.15rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20% !important;

            &-icon {
                height: 0.3rem !important;
                width: unset !important;
                max-width: 0.64rem;
                display: block;
            }

            &-name {
                font-size: 0.12rem;
                color: #13161A;
            }
        }
    }
}
</style>
