import {getCookie} from "tiny-cookie";
import { httpService} from "@/services/httpService";
import {
    SmartStorage,
} from 'smart-core-util'

export default function (){
    let params = {
        collection: "cfg-ievent-infoCard-template",
        filter: {
            platform: "app",
            tenantCode: getCookie("tenant") || SmartStorage.get("tenant"),
            tenantId: getCookie("tenant_id") || "",
        },
    };
    if(getCookie('tenant') === 'pfizer'){ // 辉瑞的查询新增一个type查询条件区分拼团、mapp等
        params.filter.type = SmartStorage.get('selectedEventType')
    }
    return new Promise(resolve => {
        httpService.GetSettings(params).then(res => {
            resolve(res);
        })
    })
}
