var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "todoBox" } },
    [
      _c(
        "div",
        {
          staticClass: "todoBox",
          on: {
            click: function ($event) {
              _vm.show = true
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c("i-icon", {
                attrs: { name: "icon-jinggao2", color: "#f87700" },
              }),
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.config["title"] ? _vm.config["title"].template : ""
                  )
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [_c("i-icon", { attrs: { name: "icon-jiantouyou" } })],
            1
          ),
        ]
      ),
      _c(
        "i-popup",
        {
          staticClass: "todoListBox",
          attrs: { position: "bottom", "get-container": "#todoBox" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              _vm._s(_vm.config["title"] ? _vm.config["title"].template : "")
            ),
          ]),
          _vm.list.length
            ? [
                _vm.config["listCard"] && _vm.config["listCard"].template
                  ? _c(
                      "div",
                      { staticClass: "listBox" },
                      _vm._l(_vm.list, function (todo, todoIndex) {
                        return _c(
                          "div",
                          { key: todoIndex, staticClass: "todoItem" },
                          [
                            _c(
                              "div",
                              { staticClass: "left" },
                              [
                                _vm.listCardTemplate.title
                                  ? _c(
                                      "div",
                                      { staticClass: "nameBox" },
                                      [
                                        _c("div", { staticClass: "dot" }),
                                        _vm._l(
                                          _vm.listCardTemplate.title,
                                          function (title, titleIndex) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: titleIndex,
                                                  class:
                                                    title["class"] || "name",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(todo[title["key"]])
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.listCardTemplate.desc
                                  ? _vm._l(
                                      _vm.listCardTemplate.desc,
                                      function (desc, descIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: descIndex,
                                            class: desc["class"] || "desc",
                                          },
                                          [_vm._v(_vm._s(todo[desc["key"]]))]
                                        )
                                      }
                                    )
                                  : _vm._e(),
                                _vm.listCardTemplate.tags
                                  ? _vm._l(
                                      _vm.listCardTemplate.tags,
                                      function (tag, tagIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: tagIndex,
                                            class: tag["class"] || "tag",
                                          },
                                          [_vm._v(_vm._s(todo[tag["key"]]))]
                                        )
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm.listCardTemplate.statusColor
                              ? _c("div", { staticClass: "right" }, [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color:
                                          _vm.listCardTemplate.statusColor[
                                            todo["taskStatus"]
                                          ],
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(todo["taskStatusTxt"]) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          !_vm.list.length
            ? _c("empty-box", { staticClass: "listBox" })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "btnBox",
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("我知道了")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }