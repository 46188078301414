<template>
    <iPopup :style="{ padding: padding, width: '100vw', height: '100vh' }" ref="pop" v-model="show" position="right">
        <div class="processModelPage" :style="pageStyle">
            <i-header :title="title" @back-event="openPop"></i-header>
            <div :style="pageStyle" class="processModelScroll">
                <ul class="processModelList" v-if="processList.length > 0">
                    <li v-for="(process, index) in processList" :key="index" :class="{ lastProcess: index == processList.length - 1 }">
                        <p class="processLiHead">{{ process[headKey] }}</p>
                        <p class="processLiContent" v-for="(key, ind) in processKey" :key="ind">{{ key.label }} {{ process[key.key] }}</p>
                        <span class="processDot">
                            <span class="processDotOn" v-if="process[onProcess]"></span>
                        </span>
                    </li>
                </ul>
                <empty-box v-else />
            </div>
        </div>
    </iPopup>
</template>

<script>
import emptyBox from "@/components/iEmpty";

export default {
    name: "ProcessModel",
    components: {
        emptyBox,
    },
    props: {
        title: String,
        headKey: String, //每行标题key
        processList: {
            type: Array,
            default() {
                return [];
            },
        },
        onProcess: String, //需显示的内容及对应key
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
        padding: {
            type: String,
            default() {
                return "";
            },
        },
        processKey: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    watch: {
        visible(newVal) {
            this.show = newVal;
        },
    },
    data() {
        return {
            pageStyle: {},
            pageStyle2: {},
            show: false,
        };
    },
    created() {
        this.setStyle();
    },
    methods: {
        setStyle() {
            this.show = this.visible;
            this.pageStyle = {
                height: "calc(100vh - 0.44rem)",
                background: "#fff",
            };
        },
        openPop() {
            this.show = false;
            this.$emit("close", false);
        },
    },
};
</script>

<style lang="less" scoped>
.processModelPage {
    background: #fff;
    width: 100vw;
}

.processHead {
    text-align: left;
    padding: 0.15rem 0.25rem;
}

.processTitle {
    color: #13161b;
    font-size: 0.24rem;
    text-align: left;
    padding: 0 0.25rem;
}

.processModelList {
    margin-top: 0.2rem;

    li {
        padding-bottom: 0.3rem;
        padding-left: 0.3rem;
        border-left: 1px solid #e5e7e9;
        position: relative;
    }
}

.processLiHead {
    color: #13161b;
    font-size: 0.16rem;
    text-align: left;
}

.processLiContent {
    font-size: 0.12rem;
    color: #8f9399;
    text-align: left;
    margin-top: 0.02rem;
    word-wrap: break-word;
}

.processDot {
    width: 0.16rem;
    height: 0.16rem;
    border-radius: 50%;
    border: 1px solid #ebf1ff;
    background: #fff;
    position: absolute;
    left: -0.09rem;
    top: 0;
}

.processDotOn {
    width: 0.1rem;
    height: 0.1rem;
    border-radius: 50%;
    background-image: linear-gradient(var(--themeColor), var(--opacityColor));
    position: absolute;
    left: 0.03rem;
    top: 0.03rem;
}

.processModelScroll {
    overflow-y: scroll;
    padding: 0 0.25rem;
}

.lastProcess {
    margin-bottom: 3rem;
}
</style>
