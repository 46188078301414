import generic from "./generic";
import {
    httpService
} from "../services/httpService";

export default {
    name: "handleProcessActions",
    data() {
        return {
            beforeActions: [],   // 所有前置操作
            processList: [],      // 所有流程操作
            afterList: [],      // 所有流程操作
        }
    },
    methods: {
        /**
         * 流程处理入口
         * @param act   流程节点
         * @param ref   表单dom
         */
        setInit(act, ref = null) {
            let _extData = act.extData || {}
            this.beforeActions = _extData.Before || []
            this.processList = _extData.InProgress || []
            this.afterList = _extData.After || []
            this.currentAct = act
            this.beforeAction(0)
        },
        // 前置操作
        beforeAction(mark = 0) {
            let actObj = this.beforeActions[mark]
            console.log('actobj',actObj)
            if (!actObj) {
                // this.toProcess(0)
                this.flowActionClick(this.currentAct)
                return false
            }
            
            let curData = this.moment().format("YYYY/MM/DD HH:mm");
            actObj = generic.replaceEvalObject(actObj,{ miceId: this.$route.query.miceId, curDate: curData })
            switch (actObj.action) {
                case 'SecondConfirm':
                    this.toConfirmAgain(actObj,mark)
                    break
            }
        },
        // 二次确认
        toConfirmAgain(actObj, mark) {
            let actDesc = actObj.descr ? actObj.descr : null
            this.$idialog.confirm({
                title: '提示',
                message: actDesc ? actDesc : `是否确定执行"${this.currentAct.actName}"操作？`,
            }).then(async () => {
                if(actObj.api) {
                    let res = await httpService.GenericPost(actObj)
                    console.log('res',res)
                    if(res && res.success) {
                        this.beforeAction(mark + 1)
                    }
                } else {
                    this.beforeAction(mark + 1)
                }
            }).catch(() => {
                return false
            });
        },
        // 流程操作
        toProcess(index) {
        },
        /**
         * 执行按钮之后
         * @param {*} param0
         * @returns
         */
        afterInProgress (executeIndex)  {
        },
    }
}
