<template>
  <div class="eventProgress" v-if="miceInfo">
    <div class="titleBox">
      <div class="nameBox">
        <div class="name">会议进展</div>
        <div class="tag" v-if="!info.isMiceStart">未开启</div>
      </div>
      <button class="btn btnBase" @click="changeStatus('start')" v-if="canStart">
        开启会议
      </button>
      <button
        class="btnBase"
        :class="canEnd == '结束会议' ? 'btn' : ' isDisabled'"
        :disabled="canEnd == '会议已结束'"
        @click="changeStatus('end')"
        v-if="info.isMiceStart || abnormalMice"
      >
        {{ canEnd }}
      </button>
    </div>
    <div class="cntBox" v-if="info.isMiceStart">
      <div class="dateBox">
        <div class="time">
          {{ info.miceOpenTime ? info.miceOpenTime.split(" ")[1] : "--:--" }}
        </div>
        <div class="date">
          {{
            info.miceOpenTime ? formatMoment(info.miceOpenTime, 1) : "--月--日"
          }}
        </div>
      </div>
      <div class="center">
        <div class="line"></div>
        <div class="duration">{{ info.duration }} 分钟</div>
        <div class="line"></div>
      </div>
      <div class="dateBox">
        <div class="time">
          {{
            info.isMiceEnd && info.miceEndTime
            ? info.miceEndTime.split(" ")[1]
            : "--:--"
          }}
        </div>
        <div class="date tr">
          {{
            info.isMiceEnd && info.miceEndTime
            ? formatMoment(info.miceEndTime, 1)
            : "--月--日"
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EventProgress",
  data() {
    return {
      curTime: "", //当前时间
      abnormalMice: false, //异常会议
      info: {
        miceOpenTime: "", //会议开始日期
        miceEndTime: "", //会议结束日期
        duration: null, //持续时间
        isMiceEnd: false, //控制会议按钮禁用
        isMiceStart: false, //是否开始会议
        isMicePeriod: false, //是否在会议时间内
      },
      isNeedApprovalEvent: false, //是否审批人
      flag: false,
    };
  },
  props: {
    miceInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    getDetail: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  computed: {
    canStart() {
      return (
        !this.info.isMiceStart &&
        !this.isNeedApprovalEvent &&
        !this.abnormalMice
      );
    },
    canEnd() {
      return !this.info.isMiceEnd &&
        this.info.isMicePeriod &&
        !this.isNeedApprovalEvent &&
        !this.abnormalMice
        ? "结束会议"
        : "会议已结束";
    },
  },
  mounted() {
    const {
      miceOpenTime,
      miceEndTime,
      duration,
      isMicePeriod,
      isMiceEnd,
      abnormalMice,
    } = this.miceInfo.ExtData;
    this.abnormalMice = abnormalMice ?? false;
    this.info = {
      ...this.info,
      miceOpenTime,
      miceEndTime,
      isMicePeriod,
      isMiceEnd,
      duration:
        duration ||
        (miceEndTime
          ? this.moment(this.formatMoment(miceEndTime)).diff(
              this.formatMoment(miceOpenTime),
              "minutes"
            )
          : this.moment(this.formatMoment(new Date())).diff(
              this.formatMoment(miceOpenTime),
              "minutes"
            )),
    };
    const { MiceId } = this.miceInfo;
    (MiceId && this.$smartStorage.get("tipsFlag_" + MiceId)) ??
      this.$smartStorage.set("tipsFlag_" + MiceId, false);
    // console.log('this.$smartStorage.get("tipsFlag_"+MiceId)---', this.$smartStorage.get("tipsFlag_"+MiceId));
    this.info.isMiceStart = Boolean(this.info.miceOpenTime);
    this.curTime = this.formatMoment(new Date());
    //获取人员角色是否为审批人
    this.isNeedApprovalEvent = Boolean(
      this.$smartStorage.get("selectedEventType") == "needApproval"
    );
    if (!this.info.miceEndTime && this.info.isMiceStart) {
      this.info.duration = this.moment(this.formatMoment(this.curTime)).diff(
        this.formatMoment(this.info?.miceOpenTime),
        "minutes"
      );
    } else if (this.info.miceEndTime && this.info.isMiceStart) {
      this.info.duration = this.moment(
        this.formatMoment(this.info?.miceEndTime)
      ).diff(this.formatMoment(this.info?.miceOpenTime), "minutes");
    }
    const startMoment = this.moment(this.miceInfo.ExtData?.DtStart);
    const endMoment = this.moment(this.miceInfo.ExtData?.DtEnd);
    // const startMoment = "2023/05/08 18:00";
    // const endMoment = "2023/05/09 20:00";
    //会议未开始&&在会议时间内&&会议未结束(未提交审批)&&不是审批人
    const isInMiceTime = this.moment(this.curTime).isBetween(
      startMoment,
      endMoment,
      null,
      "[]"
    );

    if (
      this.canStart &&
      isInMiceTime &&
      !this.$smartStorage.get("tipsFlag_" + MiceId)
    ) {
      this.$idialog
        .confirm({
          title: "提示",
          message:
            "您的会议是否已经开始，若已开始请点击详情页的会议开始按钮。会议开启后才能进行讲者签到、现场照片上传以及用餐支付",
        })
        .then(() => {
          if (confirm) {
            this.$smartStorage.set("tipsFlag_" + MiceId, true);
          }
        });
    }
  },
  methods: {
    //传入formatType,格式化-月-日
    formatMoment(time, formatType = 0) {
      if (formatType) {
        return this.moment(time).format("MM月DD日");
      } else {
        return this.moment(time).format("YYYY-MM-DD HH:mm");
      }
    },
    changeStatus(type) {
      this.curTime = this.formatMoment(new Date());
      if (!this.info.isMicePeriod && this.info.isMiceStart) {
        this.$itoast.fail("只能在会议日期内结束会议");
        return;
      } else if (!this.info.isMicePeriod && !this.info.isMiceStart) {
        this.$itoast.fail("只能在会议日期内开启会议");
        return;
      }
      if (type == "start") {
        this.info.miceOpenTime = this.curTime;
        this.info.duration = this.moment(this.curTime).diff(
          this.moment(this.info.miceOpenTime),
          "minutes"
        );
        //获取当前地址及经纬度
        this.getCurLocation();
      } else {
        let duration = this.moment(this.curTime).diff(
          this.moment(this.info.miceOpenTime),
          "minutes"
        );
        if (duration < 10) {
          this.$itoast.fail("按照公司规定会议时长不能少于10分钟");
          return;
        }
        this.$idialog
          .confirm({
            title: "提示",
            message: "确认结束会议？",
          })
          .then(() => {
            if (confirm) {
              this.info.miceEndTime = this.curTime; //结束时间为当前时间
              this.info.duration = duration;
              this.onSubmit("end"); //开始结束都要存储状态
            }
          });
      }
    },
    getCurLocation() {
      this.toastInstance = this.$itoast.loading({
        duration: 0,
        message: "正在获取GPS",
        forbidClick: true,
      });
      if (this.iJsBridge) {
        this.hasLocationPermission();
      } else {
        this.amapLocation();
      }
    },
    // 检查APP是否开启定位
    hasLocationPermission() {
      this.iJsBridge.call({
        method: "SMPermission.hasLocationPermission",
        postData: {},
        callback: this.hasLocationPermissionCallback,
      });
    },
    hasLocationPermissionCallback(data) {
      if (data === true || data === "true") {
        this.getLocation();
      } else {
        this.$itoast.fail(
          '请检查"网络是否正常"或者"移动设备的定位是否开启且允许(会引擎APP/企业微信)获取位置信息"'
        );
      }
    },
    // 原生定位
    getLocation() {
      this.iJsBridge.call({
        method: "SMAmap.getLocation",
        postData: {},
        callback: this.getLocationCallback,
      });
    },
    getLocationCallback(addressInfo) {
      console.log("原生定位回调：", addressInfo);
      if (typeof addressInfo === "string") {
        addressInfo = JSON.parse(addressInfo);
      }
      // 若原生定位失败（定位失败返回的经纬度都是0）
      if (
        addressInfo.lat == 0 ||
        addressInfo.lon == 0 ||
        addressInfo.latitude ||
        addressInfo.longitude
      ) {
        this.amapLocation();
      } else {
        this.info.imygeoLocation = {
          lat: addressInfo.lat || addressInfo.latitude,
          lng: addressInfo.lon || addressInfo.longitude,
        };
        this.toastInstance.close();
        this.onSubmit();
      }
    },
    amapLocation() {
      // 使用高德地图获取位置信息
      AMap.plugin("AMap.Geolocation", () => {
        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 5000, //超过10秒后停止定位，默认：5s
          position: "RB", //定位按钮的停靠位置
          offset: [10, 20], //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
          zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
        });
        geolocation.getCurrentPosition((status, georesult) => {
          console.log(
            "====高德地图获取到的位置信息",
            status,
            JSON.stringify(georesult)
          );
          if (status == "complete") {
            AMap.plugin("AMap.Geocoder", () => {
              var geocoder = new AMap.Geocoder();
              var lnglat = [georesult.position.lng, georesult.position.lat];
              geocoder.getAddress(lnglat, (status, result) => {
                console.log(
                  "====高德地图获取到的城市信息",
                  status,
                  JSON.stringify(result)
                );
                if (status === "complete" && result.info === "OK") {
                  // result为对应的地理位置详细信息
                  let auditData = {
                    province: result.regeocode.addressComponent.province,
                    city: result.regeocode.addressComponent.city,
                    address: result.regeocode.formattedAddress,
                  };
                  this.info.imygeoLocation = {
                    lat: georesult.position.lat,
                    lng: georesult.position.lng,
                    auditData,
                  };
                } else {
                  this.info.imygeoLocation = {
                    lat: georesult.position.lat,
                    lng: georesult.position.lng,
                    auditData: {},
                  };
                }
                  this.toastInstance.clear()
                  this.onSubmit();
              });
            });
          } else {
              this.toastInstance.clear()
            this.$itoast.fail(
              '请检查"网络是否正常"或者"移动设备的定位是否开启且允许(会引擎APP/企业微信)获取位置信息"'
            );
          }
        });
      });
    },
    onSubmit(type = "start") {
      const { MiceId } = this.miceInfo;
      if (type == "start") {
        this.info.imygeoLocation = JSON.stringify(this.info.imygeoLocation);
        this.info.isMiceStart = true;
      } else {
        this.info.isMiceEnd = true;
        sessionStorage.removeItem("tipsFlag_" + MiceId);
      }
      let params = {
        meta: {
          field: "ExtData",
          vals: {
            ...this.info,
          },
          find: {
            miceId: MiceId,
          },
        },
        object: "event_orders",
        schema: "smartx_crossevent",
      };

      const miceInfo = this.$smartStorage.get("miceInfo") || {};
      miceInfo.ExtData = {
        ...miceInfo.ExtData,
        ...this.info,
      };
      console.log("miceInfo ==", miceInfo);
      this.$smartStorage.set("miceInfo", miceInfo);
      this.$service.GenericJset(params).then((res) => {
        if (res && res.success) {
          //重新拉取会议详情
          this.getDetail();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.eventProgress {
  margin: 0.25rem 0.1rem 0 0.1rem;
  padding: 0.15rem;
  box-sizing: border-box;
  background: #f6f7fb;
  border-radius: 0.04rem;

  .tr {
    text-align: right;
  }

  .titleBox {
    display: flex;
    justify-content: space-between;

    .nameBox {
      display: flex;
      align-items: center;

      .name {
        font-size: 0.16rem;
        font-weight: bold;
        color: #13161a;
      }

      .tag {
        background: var(--opacityColor);
        border-radius: 0.03rem;
        padding: 0.02rem;
        box-sizing: border-box;
        font-size: 0.12rem;
        color: var(--themeColor);
        margin-left: 0.1rem;
      }
    }

    .btnBase {
      border-radius: 0.04rem;
      padding: 0.05rem 0.18rem;
      box-sizing: border-box;
      font-size: 0.14rem;
    }

    .btn {
      background: var(--themeColor);
      // box-shadow: 0px 0.02rem 0.08rem 0px rgba(26,109,202,0.4);
      color: #ffffff;
    }

    .isDisabled {
      color: #909399;
      margin-right: -0.15rem;
      background: transparent;
    }
  }

  .cntBox {
    margin-top: 0.14rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dateBox {
      .time {
        font-size: 0.24rem;
        font-weight: bold;
        color: #13161b;
      }

      .date {
        font-size: 0.12rem;
        color: #13161a;
      }
    }

    .center {
      display: flex;
      align-items: center;

      .line {
        width: 0.22rem;
        height: 1px;
        background: #e4e4e4;
      }

      .duration {
        background: var(--opacityColor);
        border-radius: 0rem;
        padding: 0.02rem;
        box-sizing: border-box;
        font-size: 0.12rem;
        color: var(--themeColor);
        margin: 0 0.1rem;
      }
    }
  }
}
</style>
<style lang="less">
.van-toast {
  width: 1.5rem;
  padding: 0.15rem;
  min-height: 0;
}
</style>
