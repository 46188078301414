var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.miceInfo
        ? [
            _c(
              "div",
              { ref: "titleBox", staticClass: "title-box" },
              [
                _c(
                  "i-sticky",
                  [
                    _c("i-header", {
                      attrs: {
                        scrollContainer: "eventDetailContainer",
                        title: "会议详情",
                        theme: "light",
                        showBack: true,
                      },
                      on: { "back-event": _vm.backPage },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "right",
                            fn: function () {
                              return [
                                _vm.$route.query.type != "callCar"
                                  ? [
                                      _vm.otherBtnList.length
                                        ? _c(
                                            "i-popover",
                                            {
                                              attrs: {
                                                "get-container": ".title-box",
                                                trigger: "click",
                                                placement: "bottom-end",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "reference",
                                                    fn: function () {
                                                      return [
                                                        _c("i-icon", {
                                                          attrs: {
                                                            color: "#fff",
                                                            name: "icon-more",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                701846842
                                              ),
                                              model: {
                                                value: _vm.showPopover,
                                                callback: function ($$v) {
                                                  _vm.showPopover = $$v
                                                },
                                                expression: "showPopover",
                                              },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "confirmMenu" },
                                                _vm._l(
                                                  _vm.otherBtnList,
                                                  function (item, index) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: index,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.changeMenu(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i-icon", {
                                                          attrs: {
                                                            color: "#13161B",
                                                            name: item.extData
                                                              .icon,
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.actName
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3286294047
                      ),
                    }),
                  ],
                  1
                ),
                _vm.mianLabelsTemplate
                  ? _c(_vm.$evalTemplate("Vnode", this.mianLabelsTemplate), {
                      tag: "component",
                      on: {
                        handleMoreTips: function ($event) {
                          _vm.showMoreTips = !_vm.showMoreTips
                        },
                      },
                    })
                  : _c("div", { staticClass: "confirmInfo" }, [
                      _c("p", { staticClass: "confirmInfoHead" }, [
                        _vm._v(_vm._s(_vm.miceInfo.Name)),
                      ]),
                      _vm.isCSPC
                        ? _c("p", { staticClass: "confirmInfoCode" }, [
                            _vm._v(_vm._s(_vm.miceInfo.PONum)),
                          ])
                        : _c("p", { staticClass: "confirmInfoCode" }, [
                            _vm._v(_vm._s(_vm.miceInfo.EventNum)),
                          ]),
                      _c("div", { staticClass: "confirmInfoMsg" }, [
                        _vm.miceInfo.DtStart
                          ? _c(
                              "div",
                              [
                                _c("i-icon", {
                                  attrs: {
                                    size: 15,
                                    color: "#fff",
                                    name: "icon-rili",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.miceInfo.DtStart.split("T")[0]) +
                                    " ~ " +
                                    _vm._s(_vm.miceInfo.DtEnd.split("T")[0]) +
                                    " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          [
                            _c("i-icon", {
                              attrs: {
                                size: 15,
                                color: "#fff",
                                name: "icon-shijian",
                              },
                            }),
                            _vm._v(" " + _vm._s(_vm.getTime || "暂无") + " "),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("i-icon", {
                              attrs: {
                                size: 15,
                                color: "#fff",
                                name: "icon-weizhi",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.miceInfo.Province && _vm.miceInfo.City
                                    ? _vm.miceInfo.Province +
                                        " - " +
                                        _vm.miceInfo.City
                                    : "" + _vm.miceInfo.City
                                ) +
                                " "
                            ),
                            _vm.miceInfo.ExtData &&
                            _vm.miceInfo.ExtData["region"]
                              ? [
                                  _vm._v(
                                    " - " +
                                      _vm._s(_vm.miceInfo.ExtData["region"])
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm.miceInfo.CustomerName
                          ? _c(
                              "div",
                              [
                                _c("i-icon", {
                                  attrs: {
                                    size: 15,
                                    color: "#fff",
                                    name: "icon-banhuiren",
                                  },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.miceInfo.CustomerName) + " "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "showMoreTips",
                          on: {
                            click: function ($event) {
                              _vm.showMoreTips = !_vm.showMoreTips
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showMoreTips
                                  ? "收起查看详细信息"
                                  : "展开查看详细信息"
                              ) +
                              " "
                          ),
                          _c("i-icon", {
                            attrs: {
                              size: 10,
                              color: "#fff",
                              name: _vm.showMoreTips
                                ? "icon-jiantoushang"
                                : "icon-jiantouxia",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                !_vm.miceInfo.Name
                  ? _c("i-skeleton", { attrs: { rowStyle: _vm.rowStyle } })
                  : _vm._e(),
              ],
              1
            ),
            _vm.miceInfo.Name && _vm.showMoreTips
              ? _c(
                  "div",
                  {
                    staticClass: "baseEventInfo",
                    style: { height: _vm.titleBoxOffsetTop },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("会议基本信息"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cnt-box" },
                      [
                        _vm.miceInfo.DirectoryId
                          ? [
                              _vm._l(
                                _vm.DirectoryIdMatchInfo,
                                function (item, index) {
                                  return [
                                    !_vm.$evalTemplate(item.visibleRuleExpr)
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "cnt-item",
                                            style: item.style || "",
                                          },
                                          [
                                            _c("div", { staticClass: "left" }, [
                                              _vm._v(_vm._s(item.label)),
                                            ]),
                                            _c("iNoCnt", {
                                              staticClass: "right",
                                              attrs: {
                                                txt: _vm.$evalTemplate(
                                                  item.render
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "choseBox",
                        on: {
                          click: function ($event) {
                            _vm.showMoreTips = false
                          },
                        },
                      },
                      [
                        _c("i-icon", {
                          attrs: {
                            size: 20,
                            color: "#909399",
                            name: "icon-jiantoushang",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c("ProcessModule", {
              attrs: {
                padding: "",
                visible: _vm.processVisible,
                processList: _vm.processList,
                title: "会议进程",
                headKey: "nodeName",
                onProcess: "processOn",
                processKey: _vm.processKey,
              },
              on: {
                close: function ($event) {
                  _vm.processVisible = false
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }