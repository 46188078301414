<template>
    <div>
        <div class="category-box" v-if="categoryList && categoryList.length">
            <div class="title">服务品类</div>
            <div class="add-btn" v-if="isCanAddCategory" @click="isChooseCategory = !isChooseCategory">
                <div>
                    <i-icon class="icon" color="var(--themeColor)" name="icon-add-bold" />
                </div>
                <div>添加服务品类</div>
                <div class="cover"></div>
            </div>
            <div class="all-category" v-if="isChooseCategory">
                <div class="row">
                    <div class="item" v-for="(item, index) in categoryItems[0]" :key="index">
                        <div class="category-icon">
                            <i-icon class="icon" :name="item.icon" />
                        </div>
                        <div class="category-name">{{ item.name }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="item" v-for="(category, catIndex) in categoryItems[1]" :key="catIndex">
                        <div class="category-icon">
                            <i-icon class="icon" :name="category.icon" />
                        </div>
                        <div class="category-name">{{ category.name }}</div>
                    </div>
                </div>
            </div>
            <div class="the-checked-box">
                <div class="the-checked-item" v-for="(item, index) in categoryList"
                     :key="index" @click="changeService(index, item)">
                    <div class="left">
                        <i-icon class="left" color="#000000" :name="item.icon" size="25" />
                        <div class="cnt-box">
                            <div class="cnt-title">
                                <div class="title-name">{{ item.name }} <span v-if="item.orderCount">({{ item.orderCount }})</span></div>
                                <div class="title-tag" v-if="item.orderFlag == 2">未完成</div>
                            </div>
                            <div v-if="item.orderFlag > 0" class="cnt-desc" :style="{ width: !item.quoteAmt && !item.settleAmt ? '' : '2.3rem' }">
                                <div v-if="!isNovartis" style="margin-right: 0.07rem">预算<iFixedNum :num="item.quoteAmt" /></div>
                                <div>结算<iFixedNum :num="item.settleAmt" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="tag" v-if="item.orderFlag == 0 && miceInfo.Status > 0 && miceInfo.Status <= 50
                        && !(['OfflineCar','Hotel','NonPlatform'].includes(item.code)) && !isTMIS">去添加</div>
                        <!-- note：老毕说的，辉瑞不要‘去添加’ -->
                        <div class="process" v-else-if="isMSD && item.name === '城市车队'" @click.stop="openProcess">进程</div>
                        <i-icon v-else name="icon-jiantouyou" size="14" />
                    </div>
                </div>
            </div>
        </div>
        <i-skeleton v-else :skList="cateList" />
    </div>
</template>

<script>
import { iFixedNum } from "@/components/iToolCase";
import {getCookie} from "tiny-cookie";
import {SmartStorage} from "smart-core-util";

export default {
    name: "ServiceCategory",
    components: {
        iFixedNum,
    },
    props: {
        miceInfo: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        isDsmTravel(){ // 住友的差旅
            return getCookie('tenant') === 'dsm' && SmartStorage.get('selectedEventType') === 'approval';
        },
        isMSD(){
            return getCookie('tenant') === 'msd'
        },
        isTMIS(){ // 辉瑞老yes
            return getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'TMIS'
        },
        isNovartis(){ // 辉瑞老yes
            return getCookie('tenant') === 'novartis';
        }
    },
    data() {
        return {
            orderId: "",
            serviceOn: 0,
            isChooseCategory: false,
            isCanAddCategory: false,
            categoryList: [],
            categoryItems: [
                [
                    { name: "酒店", icon: "icondalou" },
                    { name: "城市车队", icon: "iconqiche" },
                    { name: "平台车队", icon: "iconwangyueche2" },
                    { name: "制作物/设备", icon: "iconzhizuowu" },
                ],
                [
                    { name: "餐饮服务", icon: "iconshangdianfeicanyin" },
                    { name: "人员服务", icon: "iconicon_meeting" },
                    { name: "视频会议", icon: "iconshipinhuiyi" },
                    { name: "大交通", icon: "icondajiaotong" },
                ],
            ],
            cateList: [
                {
                    width: "40%",
                    inline: true,
                },
                {
                    height: "1.2rem",
                    width: "47%",
                },
                {
                    ml: "6%",
                    height: "1.2rem",
                    width: "47%",
                },
                {
                    height: "1.2rem",
                    width: "47%",
                },
                {
                    ml: "6%",
                    height: "1.2rem",
                    width: "47%",
                },
                {
                    height: ".5rem",
                    width: "100%",
                    mt: ".3rem",
                },
            ],
            debounce: true,
        };
    },
    created() {
        this.$root.$eventHub.$on("webAppBridgeListener", () => {
            this.getMiceItemCategories();
        });

        this.getMiceItemCategories();
    },
    destroyed() {
        this.$root.$eventHub.$off("webAppBridgeListener");
    },
    methods: {
        // 默沙东打开进程
        openProcess(e) {
            e.stopPropagation();
            let appConf = {
                webAppAlias: 'webapp-icar',
                path: `/cityProjectProcess?proposalId=${this.miceInfo.MiceId}&type=tenant`
            };
            this.$publicFun.openWebAppCate({}, appConf, false);
        },
        changeService(index, item) {
            if (this.debounce) {
                this.debounce = false;
                this.serviceOn = index;
                this.openWebapp(item);
            }
            setTimeout(() => {
                this.debounce = true;
            }, 500);
        },
        //获取服务品类
        getMiceItemCategories() {
            let params = {
                miceId: this.miceInfo.MiceId,
                type: this.isTMIS ? 3 : 2,
                OrderId: this.miceInfo.OrderId,
            };
            this.$service.GetCategories(params).then(async (res) => {
                if (res.success) {
                    this.categoryList = res.content;
                    if(getCookie('tenant') === 'msd'){ // 默沙东的订单数量获取
                        const numList = await this.getOrderCountForMSD();
                        this.categoryList = res.content.map(i => {
                            numList.forEach(x => {
                                if(i.name === x.itemName){
                                    i.orderCount = x.orderCount;
                                }
                            })
                            return i
                        })
                    }
                    console.log('categoryList',this.categoryList);
                    // 如果是代叫车进来的，只显示平台用车
                    if (this.$route.query.type == "callCar" || this.isDsmTravel) { // 差旅也只有平台叫车
                        this.categoryList = this.categoryList.filter((x) => x.code == "OnlineCar");
                    }
                }
            });
        },
        // 获取订单数量默沙东单独的
        getOrderCountForMSD(){
            return new Promise(resolve => {
                this.$service.QueryItemsForMSD({IsNew: true, miceId: this.miceInfo.MiceId}).then(res => {
                    if(res.success){
                        resolve(res.content)
                    }
                })
            })

        },
        //打开webview
        openWebapp(item) {
            let appData = {
                miceInfo: this.miceInfo,
                isFromMore: false,
                callback: this.broadcast,
            };
            this.$publicFun.openWebAppCate(appData, item);
        },
        //webview回调, 关闭站点回调
        broadcast() {
            //采用BUS广播好处，这样全局都能监听，无须在各个组件里面创建私有函数去监听
            console.log("broadcast");
            this.$root.$eventHub.$emit("webAppBridgeListener"); //返回刷新
        },
    },
};
</script>

<style lang="less" scoped>
.category-box {
    padding: 0.15rem 0.1rem 0 0.1rem;
    width: 100%;
    box-sizing: border-box;
    .title {
        margin-left: 0.08rem;
        font-size: 0.16rem;
        font-weight: bold;
        color: #13161a;
        text-align: left;
    }
    .add-btn {
        width: 100%;
        height: 0.5rem;
        border-radius: 0.13rem;
        margin-top: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        /deep/ .icon {
            width: 0.16rem;
            height: 0.16rem;
            display: block;
            margin-right: 0.09rem;
        }
        :nth-child(2) {
            font-size: 0.16rem;
            font-weight: 500;
            color: var(--themeColor);
        }
        .cover {
            position: absolute;
            width: 100%;
            height: 0.5rem;
            top: 0;
            left: 0;
            background: var(--themeColor);
            opacity: 0.15;
            border-radius: 0.13rem;
        }
    }
    .all-category {
        width: 100%;
        height: 2.01rem;
        background: #fafafa;
        border-radius: 0.13rem;
        margin-top: 0.1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .row {
            display: flex;
            justify-content: space-around;
            .item {
                min-width: 0.7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .category-icon {
                    width: 0.5rem;
                    height: 0.5rem;
                    background: linear-gradient(-55deg, #f0f2f7, #f0f2f7);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    /deep/ .icon {
                        width: 0.25rem;
                        height: 0.25rem;
                        display: block;
                    }
                }
                .category-name {
                    margin-top: 0.11rem;
                    font-size: 0.12rem;
                    color: #909398;
                }
            }
        }
    }
    .the-checked-box {
        margin-top: 0.17rem;
        .the-checked-item {
            width: 100%;
            height: 0.6rem;
            background: linear-gradient(-55deg, #FFFFFF, #F8F8F8);
            box-shadow: 0px 0.02rem 0.08rem 0px rgba(0,0,0,0.15);
            border-radius: 0.04rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.12rem 0.18rem 0.12rem 0.15rem;
            box-sizing: border-box;
            margin-bottom: 0.08rem;
            .left {
                display: flex;
                align-items: center;
                .cnt-box {
                    margin-left: 0.15rem;
                    .cnt-title {
                        font-size: 0.16rem;
                        font-weight: bold;
                        color: #13161A;
                        display: flex;
                        align-items: center;
                        .title-name {
                            display: flex;
                            align-items: center;
                            font-size: 0.16rem;
                            min-width: 0.7rem;
                        }
                        .title-tag {
                            background: #FCDF80;
                            border-radius: 0.04rem;
                            font-size: 0.1rem;
                            font-weight: 500;
                            color: #6E5608;
                            padding: 0.015rem 0.04rem;
                            margin-left: 0.07rem;
                        }
                    }
                    .cnt-desc {
                        display: flex;
                        width: 1.77rem;
                        font-size: 0.12rem;
                        font-weight: 500;
                        color: #909399;
                    }
                }
            }
            .right {
                display: flex;
                align-items: center;
                height: 0.3rem;
                .process{
                    padding: 0.06rem 0.12rem;
                    border-radius: 0.04rem;
                    color: var(--themeColor);
                    background-color: var(--opacityColor);
                }
                .tag {
                    width: 0.6rem;
                    height: 0.3rem;
                    background: var(--opacityColor);
                    border-radius: 0.04rem;
                    font-size: 0.14rem;
                    font-weight: 500;
                    color: var(--themeColor);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .the-checked-item:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
