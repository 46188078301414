var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "budget-box" }, [
    _c("div", { staticClass: "title" }, [_vm._v("预算信息")]),
    _c("div", { staticClass: "item-box" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "left" }, [_vm._v("总预算")]),
        _c(
          "div",
          { staticClass: "right" },
          [_c("iFixedNum", { attrs: { num: _vm.budget.totalBudget } })],
          1
        ),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "left" }, [_vm._v("已使用")]),
        _c(
          "div",
          { staticClass: "right" },
          [_c("iFixedNum", { attrs: { num: _vm.budget.usedBudget } })],
          1
        ),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "left" }, [_vm._v("可使用")]),
        _c(
          "div",
          { staticClass: "right" },
          [_c("iFixedNum", { attrs: { num: _vm.budget.availableBudget } })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }