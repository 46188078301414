<template>
    <div class="budget-box">
        <div class="title">预算信息</div>
        <div class="item-box">
            <div class="item">
                <div class="left">总预算</div>
                <div class="right">
                    <iFixedNum :num="budget.totalBudget" />
                </div>
            </div>
            <div class="item">
                <div class="left">已使用</div>
                <div class="right">
                    <iFixedNum :num="budget.usedBudget" />
                </div>
            </div>
            <div class="item">
                <div class="left">可使用</div>
                <div class="right">
                    <iFixedNum :num="budget.availableBudget" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { iFixedNum } from "@/components/iToolCase";
export default {
    name: "BudgetCard",
    components: {
        iFixedNum,
    },
    data() {
        return {
            budget: {
                availableBudget: 0,
                totalBudget: 0,
                usedBudget: 0,
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            let params = {
                MiceId: this.$route.query.miceId,
            };
            let res = await this.$service.Query_Event_Budget(params);
            if (res && res.success && res.content) {
                this.budget = res.content;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.budget-box {
    padding: 0.15rem 0.18rem 0 0.18rem;
    font-size: 0.13rem;
    .title {
        color: #13161a;
        font-size: 0.16rem;
        font-weight: bold;
        text-align: left;
    }
    .item-box {
        .item {
            display: flex;
            justify-content: space-between;
            margin-top: 0.1rem;
            .left {
                font-size: 0.14rem;
                color: #13161b;
            }
            .right span {
                font-size: 0.16rem;
                font-weight: bold;
                color: #13161b;
            }
        }
    }
}
</style>
