var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.setup
    ? _c(
        "div",
        {
          staticClass: "event-detail-container",
          attrs: { id: "eventDetailContainer" },
          on: {
            "&scroll": function ($event) {
              return _vm.onScroll($event)
            },
          },
        },
        [
          _c("MiceInfoCard", {
            attrs: {
              otherBtnList: _vm.otherBtnList,
              miceInfo: _vm.miceInfo,
              "task-id": _vm.taskId,
            },
          }),
          _vm.miceInfo
            ? [
                _vm.$route.query.type == "callCar"
                  ? [
                      _c("ServiceCategory", {
                        attrs: { miceInfo: _vm.miceInfo },
                      }),
                    ]
                  : [
                      _vm._l(_vm.moduleList, function (module, index) {
                        return [
                          _c(module.component, {
                            key: index,
                            tag: "component",
                            attrs: {
                              miceInfo: _vm.miceInfo,
                              getDetail: _vm.getDetail,
                            },
                          }),
                        ]
                      }),
                    ],
                _vm.$route.query.type != "callCar"
                  ? [
                      _vm.flowBtnList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "btn-box" },
                            _vm._l(_vm.flowBtnList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "btn-item" },
                                [
                                  _c(
                                    "i-button",
                                    {
                                      class: item.extData.class || "",
                                      attrs: {
                                        block: "",
                                        round: "",
                                        type: item.extData.type,
                                        loading: item.loading,
                                        size: "normal",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleProcessAct(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.actName) + " ")]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _c(
                  "iDialogModule",
                  {
                    attrs: {
                      isShow: _vm.showSendBack,
                      title: _vm.sendBackTitle,
                      "show-cancel-button": true,
                    },
                    on: { beforeClose: _vm.beforeClose },
                  },
                  [
                    _c("i-field", {
                      attrs: {
                        rows: "3",
                        autosize: "",
                        error: _vm.isActDescrEmpty,
                        border: false,
                        type: "textarea",
                        maxlength: "500",
                        placeholder: "请输入" + _vm.sendBackTitle,
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.actDescr,
                        callback: function ($$v) {
                          _vm.actDescr = $$v
                        },
                        expression: "actDescr",
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }